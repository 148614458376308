import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessStatus } from '../../../../models/process-status';
import { ProcessStatusFacade } from '../process-status.facade';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessStatusComponent implements OnInit {

  processStatuses$!: Observable<ProcessStatus[]>;
  
  temporalServer: string;

  constructor(private facade: ProcessStatusFacade) {
		
		this.temporalServer = environment.temporalServer;
	}

  ngOnInit(): void {
    this.processStatuses$ = this.facade.getProcessStatuses$();
  }

}
