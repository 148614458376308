<div class="process-status" *ngIf="processStatuses$ | async as processes">
  <small>
		<a href="{{temporalServer}}" class="align-right" target="_blank">Detailed Queue</a>&nbsp;&nbsp;
	</small>
	<p-dataView [value]="processes" layout="list">
    <ng-template let-process pTemplate="listItem" class="flex-column">
      <process-status-item [processStatus]="process"></process-status-item>
      <p-divider class="w-full p-0"></p-divider>
    </ng-template>
  </p-dataView>
</div>
