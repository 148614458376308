import { ProcessType, getProcessType } from './request';

export abstract class IFileInfo {
  project!: Project;
  name!: string;
  type!: string;
  relPath!: string;
  rawData!: RawFileInfo;
  results?: boolean = false;

  public constructor(init?: Partial<IFileInfo>) {
    Object.assign(this, init);
  }
}

export abstract class IFile extends IFileInfo {
  size!: number;
  fileExtension!: string | null;
}

export class ImageFile extends IFile {
  height!: number;
  width!: number;

  public constructor(rawData: any, results: boolean) {
    super();
    this.rawData = rawData;
    Object.assign(this, <Partial<FileProject>>rawData);
    this.results = results;
    this.fileExtension = getFileExtension(this.relPath);
  }
}

export class BasicFile extends IFile {
  public constructor(rawData: any, results: boolean) {
    super();
    Object.assign(this, <Partial<FileProject>>rawData);
    this.rawData = rawData;
    this.results = results;
    this.fileExtension = getFileExtension(this.relPath);
  }
}

/*
(?:         # begin non-capturing group
  \.        #   a dot
  (         #   begin capturing group (captures the actual extension)
    [^.]+   #     anything except a dot, multiple times
  )         #   end capturing group
)?          # end non-capturing group, make it optional
$           # anchor to the end of the string
 */
const re = /(?:\.([^.]+))?$/;
function getFileExtension(fileName: string | undefined): string | null {
  const extension = (fileName) ? re.exec(fileName) : null;
  if (extension) {
    return extension[1];
  } else {
    return extension;
  }
}

export class FileFolder extends IFileInfo {
  processType!: ProcessType;

  public constructor(rawData: any, resultsFolder: string) {
    super();
    Object.assign(this, <Partial<FileProject>>rawData);
    this.rawData = rawData;
    this.results = !!(resultsFolder);
    this.processType = getProcessType(resultsFolder);
  }
}

export class FileProject extends IFileInfo {
  public constructor(rawData: any) {
    super();
    Object.assign(this, <Partial<FileProject>>rawData);
    this.rawData = rawData;
  }
}

export class RawFile extends IFileInfo {
  public constructor(rawData: any) {
    super();
    Object.assign(this, <Partial<FileProject>>rawData);
    this.rawData = rawData;
  }
}

export class RawFileInfo {
  width?: number = 0;
  height?: number = 0;
  supportedImage?: boolean = false;
  size?: number | null = 0;
  resultFile?: boolean = false;
  resultsFolder?: ProcessType | null = null;
  resultsSummary?: string | null = null;
  processNumber?: number = 0;
  project!: Project;
  name!: string;
  type!: string;
  relPath!: string;
}

export class Project {
  id!: number;
  name!: string;
  bucket!: string;

  public constructor(init?: Partial<Project>) {
    Object.assign(this, init);
  }

  static toRawFileInfo(proj: Project): RawFileInfo {
    const info: RawFileInfo = {
      name: proj.name,
      type: 'Project',
      relPath: '/',
      project: proj,
    };
    return info;
  }

  toFileInfo(): RawFileInfo {
    return Project.toRawFileInfo(this);
  }
}

export class DiskUsage {
  project!: Project;
  used!: number;
  total!: number;
}

export enum DisplayType {
  Diagram = 'Diagram',
  ResultsSummary = 'ResultsSummary',
  Code = 'Code',
  TabDelimited = 'TabDelimited',
  None = 'None',
}

export class ImageInfo {
  isGrayscale!: boolean;
  trueImageSize!: number[];
  urls!: string[];
  isStack!: boolean;
  showStack!: boolean;
  scaleRatio!: boolean;
  fileName!: string;
  roiJsonStr!: string | undefined;
  imageMeta!: ImageMetadata[];
}

export class FileMetadata {
  bitsPerPixel!: number;
  fileName!: string;
  imageCount!: number;
  imageMeta!: [ImageMetadata];

  zoomStatus!: ZoomStatus;

  public toString(): string {
    return `bitsPerPixel: ${this.bitsPerPixel}, fileName: ${this.fileName}, imageCount: ${this.imageCount},
          imageMetadata: ${this.imageMeta}`;
  }
}

export class ImageMetadata {
  channelCount!: number;
  x!: number;
  y!: number;
  z!: number;
}

export class ZoomStatus {
  cached!: false;
  fileSize!: number;
  lifetime!: number;
  localPath!: string;
  timeStamp!: number;
}
