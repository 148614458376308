import { IFileInfo, ImageFile, RawFileInfo } from './file-info';
import { Rectangle, Polygon } from './plotting/region';

export enum ProcessType {
  CROP = 'CROP',
  FIND = 'FIND',
  HTQUANT = 'HTQUANT',
  SIFT = 'SIFT',
  EDOF = 'EDOF',
  KIDNEY = 'KIDNEY_CLASSIFIER',
  DECONVOLUTION = 'DECONVOLUTION',
  YOLOSEGDETECT = 'YOLO_SEGDETECT',
  RETINAL_LAYER = 'RETINAL_LAYER',
  UNKNOWN = 'UNKNOWN',
}

export function getProcessType(type: string): ProcessType {
  const xProcessType: ProcessType | undefined = (<any>ProcessType)[type];
  if (xProcessType != undefined) {
    return xProcessType;
  } else {
    return ProcessType.UNKNOWN;
  }
}

export class Processor {
  name!: string;
  icon!: string;
  desc!: string;
  id!: ProcessType;
}

export interface Request {
  processType: ProcessType;
}

export class QuantRequest implements Request {
  processType = ProcessType.HTQUANT;
  outputName: string | null = null;
  regions: QuantRegion[] = [];
  regionSeries = 0;
  fiberSettings = new ParticleSettings();
  nucleiSettings = new ParticleSettings();
  contrastProperties = new ContrastProperties();
}

export class ParticleSettings {
  min = 0;
  max = Number.MAX_VALUE;
  cMin = 0.0;
  cMax = 1.0;
  hardBounds = true;
}

export class ContrastProperties {
  stretch = true;
  saturated = 1.0;
  normalize = true;
  gammaCorrection = false;
  // Range 0.05 - 5
  gamma = 1.0;
}

export class QuantRegion {
  name?: string;
  fileName?: string;
  polygon?: Polygon;
}

/**
 *
 * Eventually this will be a large class to hold the options for
 * cropping. For now it has only to zip or not to zip.
 */
export class SiftRequest implements Request {
  processType = ProcessType.SIFT;

  /**
   * Path in bucket to file we must align
   */
  align?: StorageKey;

  /**
   * Path in bucket to reference file
   */
  reference?: StorageKey;

  /**
   * Path in bucket to where the sift output should be written.
   */
  siftOutput?: StorageKey;

  /**
   * Path in bucket to where the shg results are.
   */
  shg?: StorageKey;

  /**
   * Whether Alignment image will be transformed or SHG/FL. True means img1 will get transformed or img3.
   * True values are y, yes, t, true, on and 1; false values are n, no, f, false, off and 0. Raises
   * ValueError if val is anything else.
   */
  transform?: boolean;

  /**
   * Vertical flip yes or no
   */
  flip?: boolean;

  /**
   * Will downsample the images if total pixel is greater than 9e7
   */
  downsample?: boolean;

  /**
   * Gamma correction value for overlay
   */
  gamma?: number;

  /**
   * Resized NZ output file in case of downsample
   */
  resizedN?: StorageKey;

  /**
   * Resized SHG/FL output file in case of downsample
   */
  resizedSF?: StorageKey;

  /**
   * Path in bucket to where the overlayed result
   * should be written.
   */
  overlayedResult?: StorageKey;
}

/**
 *
 * Eventually this will be a large class to hold the options for
 * cropping. For now it has only to zip or not to zip.
 */
export class EDoFRequest implements Request {
  processType = ProcessType.EDOF;

  /**
   * Path in bucket to file we must align
   */
  input: StorageKey[] = [];

  /**
   * Path in bucket to reference file
   */
  outputImage?: StorageKey;

  /**
   * output zmap name.
   */
  outputZmap?: StorageKey;

  /**
   * TODO Path in bucket to where the shg results are.
   */
  inputZmap?: StorageKey;

  /**
   -gradient_kernel=(5)
   Size of kernel used for computation of image gradients.  Must be 1, 3, 5, 7.
   **/
  gradientKernel?: number;

  /**
   -noise_filter=(3)
   Size of median filter used to reduce noise in the z-map. Must be 0, 3 or 5
   **/
  noiseFilter?: number;

  /**
   -low_pass=(2.000000)
   zmap lowpass filter object size.
   **/
  lowPass?: number;

}

/**
 *
 * Eventually this will be a large class to hold the options for
 * cropping. For now it has only to zip or not to zip.
 */
export class KidneyClassifierRequest implements Request {
  processType = ProcessType.KIDNEY;

  /**
   * Name of directory where raw classifier scores are saved.
   */
  bucket_name?: string | undefined = undefined; //'jax-cimg-sample-data';

  /**
   * Name of directory where raw classifier scores are saved.
   */
  output_bucket_name?: string = 'kidney_classifier_output';

  /**
   * Name of directory where images are stored.
   */
  stitched_image_dir?: string; // StorageKey

  /**
   * Name of directory where model is saved.
   */
  model_name?: string;

  /**
   * Name of directory where raw classifier scores are saved.
   */
  output_score_path?: string = 'SAVED_SCORES/';

  /**
   * Name of directory where processed classifier scores are saved.
   */
  combined_score_path?: string = 'COMBINED_AGE_SCORES/';

  /**
   * Name of directory where tissue masks are saved.
   */
  tissue_mask_path?: string;

  /**
   * Name of directory where csvs are saved.
   */
  damage_score_csv_path?: string;

  /**
   * Name of directory where heatmaps are saved.
   */
  heatmap_path?: string;

  /**
   * Lower bound used in painting algorithm.
   */
  lower_bound?: number = -4;

  /**
   * Upper bound used in painting algorithm.
   */
  upper_bound?: number = 4;

}

export class DeconvolutionRequest implements Request {

  processType = ProcessType.DECONVOLUTION;

  inputPath?: StorageKey;
  stain1?: number[];
  stain2?: number[];
  stain1Max = 2.0;
  stain2Max = 1.0;
  alpha = 1;
  beta = 0.15;
  intensityNorm = 240;
  grayscale = false;

  stain1ImageOutput?: StorageKey;
  stain2ImageOutput?: StorageKey;
  normalizedImageOutput?: StorageKey;
  imageStackOutput?: StorageKey;

  /**
   * @return the inputImage
   */
  getInputPath() {
    return this.inputPath;
  }

  /**
   * @param inputPath the inputImage to set
   */
  setInputPath(inputPath: StorageKey) {
    this.inputPath = inputPath;
  }

  /**
   * @return the stain1
   */
  getStain1() {
    return this.stain1;
  }

  /**
   * @param stain1 the stain1 to set
   */
  setStain1(stain1: number[]){
    this.stain1 = stain1;
  }

  /**
   * @return the stain2
   */
  getStain2() {
    return this.stain2;
  }

  /**
   * @param stain2 the stain2 to set
   */
  setStain2(stain2: number[]){
    this.stain2 = stain2;
  }

  /**
   * @return the stain1Max
   */
  getStain1Max() {
    return this.stain1Max;
  }

  /**
   * @param stain1Max the stain1Max to set
   */
  setStain1Max(stain1Max: number){
    this.stain1Max = stain1Max;
  }

  /**
   * @return the stain2Max
   */
  getStain2Max(){
    return this.stain2Max;
  }

  /**
   * @param stain2Max the stain2Max to set
   */
  setStain2Max(stain2Max: number){
    this.stain2Max = stain2Max;
  }

  /**
   * @return the alpha
   */
  getAlpha(){
    return this.alpha;
  }

  /**
   * @param alpha the alpha to set
   */
  setAlpha(alpha: number){
    this.alpha = alpha;
  }

  /**
   * @return the beta
   */
  getBeta(){
    return this.beta;
  }

  /**
   * @param beta the beta to set
   */
  setBeta(beta: number){
    this.beta = beta;
  }

  /**
   * @return the intensityNorm
   */
  getIntensityNorm(){
    return this.intensityNorm;
  }

  /**
   * @param intensityNorm the intensityNorm to set
   */
  setIntensityNorm(intensityNorm: number){
    this.intensityNorm = intensityNorm;
  }

  /**
   * @return the grayscale
   */
  isGrayscale(){
    return this.grayscale;
  }

  /**
   * @param grayscale the grayscale to set
   */
  setGrayscale(grayscale: boolean){
    this.grayscale = grayscale;
  }
  getStain1ImageOutput() {
    return this.stain1ImageOutput;
  }
  setStain1ImageOutput(stain1ImageOutput: StorageKey) {
    this.stain1ImageOutput = stain1ImageOutput;
  }

  getStain2ImageOutput() {
    return this.stain2ImageOutput;
  }
  setStain2ImageOutput(stain2ImageOutput: StorageKey) {
    this.stain2ImageOutput = stain2ImageOutput;
  }

  getNormalizedImageOutput() {
    return this.normalizedImageOutput;
  }
  setNormalizedImageOutput(normalizedImageOutput: StorageKey) {
    this.normalizedImageOutput = normalizedImageOutput;
  }

  getImageStackOutput() {
    return this.imageStackOutput;
  }
  setImageStackOutput(imageStackOutput: StorageKey) {
    this.imageStackOutput = imageStackOutput;
  }

}

export class YolosegdetectRequest implements Request {
  processType = ProcessType.YOLOSEGDETECT;

  inputPaths: StorageKey[] = []; // StorageKey
  outputPaths?: StorageKey[]; // StorageKey
  weightsPath?: StorageKey; // StorageKey

  level? = 0;
  downsamplingFactor? = 5;
  overlapX? = 60;
  overlapY? = 60;
  confidence? = 0.6;
  iouThreshold? = 0.5;
  nmsThreshold? = 0.3;
  visualize? = false;
  saveSegment? = false;
}

export class RetinalLayerRequest implements Request {
  processType = ProcessType.RETINAL_LAYER;

  images: StorageKey[] = []; // StorageKey
  predModel?: StorageKey; // StorageKey
  model: string | undefined = 'resunet_a_2d'; // can be resunet_a_2d or vnet_2d
  step: number | undefined = 512;

  outputPath?: StorageKey; // StorageKey

}

export class CropRequest implements Request {
  processType = ProcessType.CROP;
  regionSettings: RegionSettings = new RegionSettings();
  tileConfiguration: TileConfiguration = new TileConfiguration();
  cropResolution = 0;
}

export class RegionSettings {
  minimumAspectRatio = 0.15;
  margin = 10;
  findRegions = true;
  cropFoundRegionsAsRectangles = true;
  cropAsGreyScale = false;
  showRegions = false;
  generateProofImage = true;
  generatePreviews = true;
  saveAsJson = true;
  zip = true;
  manualRegions?: Polygon[] = [];
  manualRegionResolution = 0;
  forceSquare = false;
}

export class TileConfiguration {
  tileSize: number[] = [4096, 4096];

  // IMAGE: Left to right, top to bottom
  // GRAPH: Left to right, bottom to top
  order = 'IMAGE';
  clip = true;
  alwaysTile = false; // true forces tile even if image fits in memory.
}

export class Instruction {
  file!: RawFileInfo;
  request!: Request;
}

export class StorageKey {
  endpoint?: string
  bucket?: string
  object?: string
}

export class Builder  {
  bucket?: string;
  path?: string;

  keyForFile(file: IFileInfo) : StorageKey | undefined {
    if (file === undefined) return undefined;
    return this.keyForName(file.name);
  }
  keyForName(name: string) : StorageKey | undefined {
    if (name === undefined) return undefined;
    const ret: StorageKey = new StorageKey();
    ret.bucket = this.bucket;
    ret.object = this.path?.concat(name);
    return ret;
  }

  getFilePathWithoutExtension(path: string) {
    const lastDotIndex = path.lastIndexOf('.');
    let imgNoExtension = path;
    if (lastDotIndex !== -1) {
      imgNoExtension = path.slice(0, lastDotIndex);
    }
    return imgNoExtension;
  }
}

export class SiftRequestBuilder {
  bucket?: string;
  path!: string;

  // inputs
  alignFile!: ImageFile;
  referenceFile!: ImageFile;
  shgFile!: ImageFile;
  transform = true;
  downsample = false;
  flip = true;
  gamma = 1;

  // outputs
  siftOutputName?: string = 'translated.tif';
  overlayedResultName?: string = 'overlayed.tif';
  resizedNzName = 'resized_NZ.tif';
  resizedSfName = 'resized_SF.tif';
  outputFolder = '';

  build(): SiftRequest {
    const siftRequest: SiftRequest = new SiftRequest();
    siftRequest.align = this.key(this.alignFile.name);
    siftRequest.reference = this.key(this.referenceFile.name);
    siftRequest.shg = this.key(this.shgFile.name);
    siftRequest.transform = this.transform;
    siftRequest.flip = this.flip;
    siftRequest.gamma = this.gamma;
    siftRequest.downsample = this.downsample;
    siftRequest.siftOutput = this.key(this.outputFolder + '/' + this.siftOutputName);
    siftRequest.overlayedResult = this.key(this.outputFolder + '/' + this.overlayedResultName);
    siftRequest.resizedN = this.key(this.outputFolder + '/' + this.resizedNzName);
    siftRequest.resizedSF = this.key(this.outputFolder + '/' + this.resizedSfName);
    return siftRequest;
  }

  key(name: any) : StorageKey {
    const ret: StorageKey = new StorageKey();
    ret.bucket = this.bucket;
    ret.object = this.path.concat(name);
    return ret;
  }

}

export class EDoFRequestBuilder extends Builder {
  // inputs
  input?: IFileInfo;
  inputZmap?: IFileInfo;
  gradientKernel?: number = 5;
  noiseFilter?: number = 3;
  lowPass?: number = 4.0;

  // outputs
  outputFolder = '';
  outputImage = 'extended_depth.tif';
  outputZmap = 'zmap.tif';

  build(): EDoFRequest {
    const edofRequest: EDoFRequest = new EDoFRequest();
    if (this.input) {
      const input: StorageKey | undefined = this.keyForFile(this.input);
      if (input) {
        // TODO This only works for image stack single files. We may also have stack as directory.
        edofRequest.input.push(input);
      }
    }
    edofRequest.outputImage = this.keyForName(this.outputFolder + '/' + this.outputImage);

    edofRequest.outputZmap = this.keyForName(this.outputFolder + '/' + this.outputZmap);

    if (this.inputZmap) {
      edofRequest.inputZmap = this.keyForFile(this.inputZmap);
    }
    edofRequest.gradientKernel = this.gradientKernel;
    edofRequest.noiseFilter = this.noiseFilter;
    edofRequest.lowPass = this.lowPass;
    return edofRequest;
  }

}

export class YolosegdetectRequestBuilder extends Builder {
  // inputs
  inputImages?: IFileInfo[];
  modelFilePath?: string;
  modelFile?: IFileInfo;
  level?: number;
  downSamplingFactor?: number;
  overlapX?: number;
  overlapY?: number;
  confidence?: number;
  iouThreshold?: number;
  nmsThreshold?: number;
  saveSegment?: boolean;
  // outputs
  outputFolder = '';

  build(): YolosegdetectRequest {
    const yoloSegDetectRequest: YolosegdetectRequest = new YolosegdetectRequest();
    if (this.inputImages) {
      yoloSegDetectRequest.inputPaths = [];
      yoloSegDetectRequest.outputPaths = [];
      for (const image of this.inputImages) {
        const imageInput: StorageKey | undefined = this.keyForFile(image);
        if (imageInput) {
          yoloSegDetectRequest.inputPaths.push(imageInput);
          // set output
          let outputPath = undefined;
          if (this.outputFolder) {
            outputPath = this.keyForName(this.outputFolder + '/' + this.getFilePathWithoutExtension(image.name) + '.geojson');
          } else {
            outputPath = this.keyForName(this.getFilePathWithoutExtension(image.name) + '.geojson');
          }
          if (outputPath)
            yoloSegDetectRequest.outputPaths.push(outputPath);
        }
      }
    }
    // get StorageKey from modelfile path
    if (this.modelFilePath) {
      const bucket = this.modelFilePath.split('/')[0];
      const object = this.modelFilePath.split('/').slice(1).join('/');
      const modelFile: StorageKey = new StorageKey();
      modelFile.bucket = bucket;
      modelFile.object = object;
      yoloSegDetectRequest.weightsPath = modelFile;
    }
    yoloSegDetectRequest.level = this.level;
    yoloSegDetectRequest.downsamplingFactor = this.downSamplingFactor;
    yoloSegDetectRequest.overlapX = this.overlapX;
    yoloSegDetectRequest.overlapY = this.overlapY;
    yoloSegDetectRequest.confidence = this.confidence;
    yoloSegDetectRequest.iouThreshold = this.iouThreshold;
    yoloSegDetectRequest.nmsThreshold = this.nmsThreshold;
    yoloSegDetectRequest.saveSegment = this.saveSegment;
    return yoloSegDetectRequest;
  }
}

export class RetinalLayerRequestBuilder extends Builder {
  // inputs
  images?: IFileInfo[];
  predModel?: string;
  model?: string;
  step?: number;

  // outputs
  outputPath!: string;

  build(): RetinalLayerRequest {
    const retinalLayerRequest: RetinalLayerRequest = new RetinalLayerRequest();
    if (this.images) {
      retinalLayerRequest.images = [];
      for (const image of this.images) {
        const imageInput: StorageKey | undefined = this.keyForFile(image);
        if (imageInput) {
          retinalLayerRequest.images.push(imageInput);
          // set output
          let outputPath = undefined;
          if (this.outputPath) {
            outputPath = this.keyForName(this.outputPath + '/' + this.getFilePathWithoutExtension(image.name));
          } else {
            outputPath = this.keyForName(this.getFilePathWithoutExtension(image.name));
          }
          retinalLayerRequest.outputPath = outputPath;
        }
      }
    }
    // get StorageKey from modelfile path
    if (this.predModel) {
      const bucket = this.predModel.split('/')[0];
      const object = this.predModel.split('/').slice(1).join('/');
      const modelFile: StorageKey = new StorageKey();
      modelFile.bucket = bucket;
      modelFile.object = object;
      retinalLayerRequest.predModel = modelFile;
    }
    retinalLayerRequest.step = this.step;
    retinalLayerRequest.model = this.model;
    return retinalLayerRequest;
  }

}

export class KidneyClassifierRequestBuilder {
  bucket?: string;
  path!: string;

  imageDirectory!: ImageFile;
  modelFile!: string;

  // outputs
  heatmapPath?: string = 'HEAT_MAPS/';
  damageScorePath?: string = 'DAMAGE_SCORE_CSVS/';
  tissueMaskPath?: string = 'TISSUE_MASKS/'

  build(): KidneyClassifierRequest {
    const kidneyClassifierRequest: KidneyClassifierRequest = new KidneyClassifierRequest();
    const basePath = this.path.concat(this.imageDirectory.name);
    kidneyClassifierRequest.stitched_image_dir = basePath.endsWith('/') ? basePath : basePath + '/';
    kidneyClassifierRequest.model_name = this.modelFile;
    kidneyClassifierRequest.output_bucket_name = this.bucket;
    kidneyClassifierRequest.tissue_mask_path = this.tissueMaskPath;
    kidneyClassifierRequest.damage_score_csv_path = this.damageScorePath;
    kidneyClassifierRequest.heatmap_path = this.heatmapPath;
    return kidneyClassifierRequest;
  }

  key(name: any) : StorageKey {
    const ret: StorageKey = new StorageKey();
    ret.bucket = this.bucket;
    ret.object = this.path.concat(name);
    return ret;
  }

}

export class DeconvolutionBuilder extends Builder {
  // inputs
  inputImage!: IFileInfo;
  red1!: number;
  green1!: number;
  blue1!: number;
  red2!: number;
  green2!: number;
  blue2!: number;
  stain1max!: number;
  stain2max!: number;
  alpha!: number;
  beta!:number;
  intensityNorm!: number;
  grayscale!: boolean;

  // outputs
  outputPath!: string ;

  build(): DeconvolutionRequest {
    const deconvolutionRequest: DeconvolutionRequest = new DeconvolutionRequest();
    deconvolutionRequest.inputPath = this.keyForFile(this.inputImage);
    deconvolutionRequest.stain1 = [this.red1, this.green1, this.blue1];
    deconvolutionRequest.stain2 = [this.red2, this.green2, this.blue2];
    deconvolutionRequest.stain1Max = this.stain1max;
    deconvolutionRequest.stain2Max = this.stain2max;
    deconvolutionRequest.alpha = this.alpha;
    deconvolutionRequest.beta = this.beta;
    deconvolutionRequest.intensityNorm = this.intensityNorm;
    deconvolutionRequest.grayscale = this.grayscale;
    if (this.outputPath) {
      deconvolutionRequest.stain1ImageOutput = this.keyForName(this.outputPath + '/' + this.inputImage.name.split('.')[0] + '_stain1.tif');
      deconvolutionRequest.stain2ImageOutput = this.keyForName(this.outputPath + '/' + this.inputImage.name.split('.')[0] + '_stain2.tif');
      deconvolutionRequest.normalizedImageOutput = this.keyForName(this.outputPath + '/' + this.inputImage.name.split('.')[0] + '_normalized.tif');
      deconvolutionRequest.imageStackOutput = this.keyForName(this.outputPath + '/' + this.inputImage.name.split('.')[0] + '_stack.tif');
    } else {
      deconvolutionRequest.stain1ImageOutput = this.keyForName(this.inputImage.name.split('.')[0] + '_stain1.tif');
      deconvolutionRequest.stain2ImageOutput = this.keyForName(this.inputImage.name.split('.')[0] + '_stain2.tif');
      deconvolutionRequest.normalizedImageOutput = this.keyForName(this.inputImage.name.split('.')[0] + '_normalized.tif');
      deconvolutionRequest.imageStackOutput = this.keyForName(this.inputImage.name.split('.')[0] + '_stack.tif');
    }
    return deconvolutionRequest;
  }
}

export enum ValidationType {
  VALID = 'VALID',
  LIKELY_VALID = 'LIKELY_VALID',
	LIKELY_INVALID = 'LIKELY_INVALID',
  INVALID = 'INVALID',
}

export interface Validation {
  type: string,
  message: string,
}

export class ZoomRequest {
  info!: RawFileInfo;
  key!: string;
  roi!: Rectangle;
  zIndex!: number;
  screen!: Rectangle;
  resolution!: number;
}


