import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit } from '@angular/core';
import { Polygon, Rectangle, Region } from '../../models/plotting/region';
import { PlotFacade } from './plot.facade';
import { MainUtilities } from '../../main.utilities';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class SelectionDialogComponent implements OnInit {

  protected readonly Array = Array;

  regions: Region[] = [];
  regionsCopy: Region[] = [];
  selectedRegions: Region[] = [];
  showShapeIdentifier!: boolean;
  shapeColor!: string;
  identifierColor!: string;
  fillColor!: string;
  displayHelpDialog = false;
  labelColors: Map<string, string> = new Map();
  selectedLabelColor = '';

  mainUtilities: MainUtilities = new MainUtilities();

  constructor(public plotFacade: PlotFacade, public ref: DynamicDialogRef,
              public config: DynamicDialogConfig, public messageService: MessageService){}

  ngOnInit() {
    this.showShapeIdentifier = this.plotFacade.getShowShapeLabel();
    const plotShapes = this.config.data?.shapes;
    this.shapeColor = this.plotFacade.getShapeColor();
    this.identifierColor = this.plotFacade.getLabelColor();
    this.fillColor = this.plotFacade.getFillColor();
    if (plotShapes) {
      for (const plotShape of plotShapes) {
        const region = new Region();
        region.name = plotShape.name;
        if (plotShape.type === 'rect') {
          region.bounds = new Rectangle();
          region.bounds.x = plotShape.x0;
          region.bounds.y = plotShape.y0;
          region.bounds.width = plotShape.x1 - plotShape.x0;
          region.bounds.height = plotShape.y1 - plotShape.y0;
        }
        else if (plotShape.type === 'path') {
          region.bounds = new Polygon();
          const coordinates = this.pathToCoordinates(plotShape.path);
          region.bounds.npoints = coordinates.length;
          for (let i = 0; i < coordinates.length; i++) {
            region.bounds.xpoints.push(coordinates[i][0]);
            region.bounds.ypoints.push(coordinates[i][1]);
          }
          region.bounds.coordinates = coordinates;
        }
        region.label = plotShape.legend;
        if (plotShape.line.color) {
          region.color = plotShape.line.color;
        } else {
          region.color = this.shapeColor;
        }
        this.regions.push(region);
      }
      // set labels
      for (const region of this.regions) {
        if (region.label && region.color) {
          this.labelColors.set(region.label, region.color);
        }
      }
      this.regionsCopy = this.mainUtilities.deepClone(this.regions);
    }

  }

  save() {
    this.ref.close({ shapes: this.regions, showLabel: this.showShapeIdentifier,
            shapeColor: this.shapeColor, fillColor: this.fillColor, labelColor: this.identifierColor });
  }

  cancel() {
    this.ref.close();
  }

  addRectangle() {
    const region = new Region();
    region.bounds = new Rectangle();
    region.bounds.width = 512;
    region.bounds.height = 512;
    region.name = `shape${ this.regions.length }`;
    this.regions.push(region);
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }

  /**
   * Update the label of the region on enter key pressed (when editing a label cell in the table)
   * @param region
   */
  labelRegionUpdate(region: Region) {
    if (region.label) {
      // if label doesn't exist
      if (!this.labelColors.has(region.label)) {
        this.labelColors.set(region.label, this.shapeColor);
      }
    }
    // update colors of the regions
    for (const reg of this.regions) {
      if (reg.label && this.labelColors.has(reg.label)) {
        reg.color = this.labelColors.get(reg.label);
      }
    }
    // update labels map
    this.labelColors.clear();
    for (const reg of this.regions) {
      if (reg.label && reg.color) {
        this.labelColors.set(reg.label, reg.color);
      }
    }
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }
  xRectUpdate(region: Region, value: number) {
    const rectangle = region.bounds;
    if (rectangle && rectangle instanceof Rectangle) {
      rectangle.x = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  yRectUpdate(region: Region, value: number) {
    const rectangle = region.bounds;
    if (rectangle && rectangle instanceof Rectangle) {
      rectangle.y = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  widthRectUpdate(region: Region, value: number) {
    // retrieve old region
    const reg = this.regionsCopy.filter((element: Region) => {
      return element.name == region.name;
    });
    const oldRect = reg[0].bounds;
    const rectangle = region.bounds;
    if (rectangle && rectangle instanceof Rectangle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const oldWidth = oldRect.width;
      const diffWidth = value - oldWidth;
      // set x new coord
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rectangle.x = Math.round(oldRect.x - (diffWidth / 2));
      rectangle.width = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  heightRectUpdate(region: Region, value: number) {
    // retrieve old region
    const reg = this.regionsCopy.filter((element: Region) => {
      return element.name == region.name;
    });
    const oldRect = reg[0].bounds;
    const rectangle = region.bounds;
    if (rectangle && rectangle instanceof Rectangle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const oldHeight = oldRect.height;
      const diffHeight = value - oldHeight;
      // set y new coord
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rectangle.y = Math.round(oldRect.y - (diffHeight / 2));
      rectangle.height = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }

  deleteSelectedRegions() {
    this.regions = this.regions.filter(val => !this.selectedRegions.includes(val));
    this.selectedRegions = [];
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }

  addPolygon() {
    const region = new Region();
    region.bounds = new Polygon();
    region.name = `shape${ this.regions.length }`;
    region.bounds.ypoints = [0, 0, 0];
    region.bounds.xpoints = [0, 0, 0]
    region.bounds.coordinates = [[0, 0], [0, 0], [0, 0]];
    region.bounds.npoints = 3;
    this.regions.push(region);
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }
  deleteRegion(shapeIdx: number) {
    this.regions.splice(shapeIdx, 1);
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }
  addCoordinate(region: Region) {
    const polygon = region.bounds;
    if (polygon && polygon instanceof Polygon) {
      polygon.coordinates.push([0, 0]);
      polygon.xpoints.push(0);
      polygon.ypoints.push(0);
      polygon.npoints += 1;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  xCoordinateUpdate(region: Region, coordIdx: number, value: number) {
    const bounds = region.bounds;
    if (bounds instanceof Polygon) {
      bounds.coordinates[coordIdx][0] = value;
      bounds.xpoints[coordIdx] = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  yCoordinateUpdate(region: Region, coordIdx: number, value: number) {
    const bounds = region.bounds;
    if (bounds instanceof Polygon) {
      bounds.coordinates[coordIdx][1] = value;
      bounds.ypoints[coordIdx] = value;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }
  deleteCoordinate(region: Region, ri: number) {
    let coordinates: any[];
    const bounds = region.bounds;
    if (bounds instanceof Polygon) {
      coordinates = bounds.coordinates;
      coordinates.splice(ri, 1);
      bounds.xpoints.splice(ri, 1);
      bounds.ypoints.splice(ri, 1);
      bounds.npoints = coordinates.length;
      this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
    }
  }

  changeShowShapeIdentifier(showIdentifier: boolean) {
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }

  isRectangle(region: Region) {
    return region.bounds instanceof Rectangle;
  }

  /**
   * This method rounds all the rectangle regions lengths to multiple of 512
   */
  roundRectangleLengths() {
    for (const region of this.regions) {
      if (region.bounds instanceof Rectangle) {
        const rect = region.bounds;
        rect.height = Math.round(rect.height / 512) * 512;
        rect.width = Math.round(rect.width / 512) * 512;
      }
    }
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
  }

  /**
   * Returns a list of coordinates given a path (M*L*L*L...Z)
   * @param path
   */
  pathToCoordinates(path: string) {
    const coordinates = [];
    if (!path.startsWith('M')) {
      throw Error('The string path is missing the \'M\' prefix.');
    }
    if (!path.endsWith('Z')) {
      throw Error('The string path is missing the \'Z\' suffix.');
    }
    // remove 1rst and last characters (M and Z)
    const tmp = path.substring(1, path.length - 1);
    const array = tmp.split('L');
    if (array.length < 1) {
      throw Error('the string path is missing the \'L\' separator.');
    }
    for (let i = 0; i < array.length; i++) {
      const xy = array[i].split(',');
      // round the coordinates to the 5 decimal place
      coordinates.push([parseFloat(parseFloat(xy[0]).toFixed(5)),
                        parseFloat(parseFloat(xy[1]).toFixed(5))]);
    }
    return coordinates;
  }

  /**
   * Stop key arrow event propagation
   * @param event
   */
  disableArrowKeys(event: any) {
    if (event.key === 'ArrowDown' || event.key === 'Down'
        || event.key === 'ArrowUp' || event.key === 'Up'
        || event.key === 'ArrowLeft' || event.key === 'Left'
        || event.key === 'ArrowRight' || event.key === 'Right') {
      event.stopPropagation();
    }
  }

  changeSelectedLabelColor(label: string) {
    // if color has already been set for the label/class
    const color = this.labelColors.get(label);
    if (color) {
      this.shapeColor = color;
    }
    this.selectedLabelColor = label;
  }

  changeShapeColor(shapecolor: string) {
    this.shapeColor = shapecolor;
    for (const region of this.regions) {
      if (region.label === this.selectedLabelColor && this.labelColors.has(region.label)) {
        this.labelColors.set(region.label, this.shapeColor);
        region.color = this.shapeColor;
      }
    }
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false,
                               this.fillColor, this.identifierColor);
  }
  changeIdentifierColor(identifierColor: string) {
    this.identifierColor = identifierColor;
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false,
      this.fillColor, this.identifierColor);
  }
  changeFillColor(fillcolor: string) {
    this.fillColor = fillcolor;
    this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false,
      this.fillColor, this.identifierColor);
  }

  showHelp() {
    this.displayHelpDialog = true;
  }

  importRois(fileSelected: any) {
    const file = fileSelected.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result;
      try {
        this.regions = this.plotFacade.importRegions(fileContent);
        this.plotFacade.setRegions(this.regions, this.showShapeIdentifier, false);
      } catch (event) {
        this.messageService.add({ severity: 'error', summary: 'Error importing the file', detail: `${event}` });
        console.error('Error reading the file: ' + event);
      }
    };
    reader.readAsText(file);
  }

  exportRois() {
    this.plotFacade.exportRegions(this.regions);
  }

}
