import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { IFileInfo,
  RawFileInfo,
  ImageFile,
  FileMetadata,
  DisplayType,
  ImageInfo
} from '../main/models/file-info';
import { TableData } from '../main/models/table-data';
import { ResultsSummary } from '../main/models/results';
import { ZoomRequest } from '../main/models/request';
import { MainState } from '../main/main.state';
import { MessageService, TreeNode } from 'primeng/api';
import { MainUtilities } from '../main/main.utilities';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private url: string;

  private selectedFile!: IFileInfo | undefined;
  private selectedNode!: TreeNode<IFileInfo> | null;

  mainUtilities: MainUtilities = new MainUtilities();
  private rootNodes: TreeNode<IFileInfo>[] = [];

  fileMetadataSubject = new BehaviorSubject<FileMetadata>(new FileMetadata());
  getfileMetaData$ = this.fileMetadataSubject.asObservable();

  zoomOnRegionSubject = new BehaviorSubject<any>(null);
  zoomOnRegionAction$ = this.zoomOnRegionSubject.asObservable();

  constructor(private http: HttpClient, private mainState: MainState, private messageService: MessageService) {
    this.url = environment.slideCropServer;
    mainState.getSelectedNode$().subscribe(
      (node) => {
        this.selectedNode = node;
        this.mainState.setSelectedFile(node?.data);
        this.selectedFile = node?.data;
      }
    );
    mainState.getFileInfo$().subscribe({
      next: fileInfo => {
        const nodes = this.mainUtilities.createNodes(fileInfo);
        if (this.rootNodes.length === 0) {
          this.mainState.setRootNodes(nodes);
        } else {
          this.selectedNode?.children?.push(...nodes);
        }
      },
    });
    mainState.getRootNodes$().subscribe(
      (rootNodes) => {
        this.rootNodes = rootNodes;
      }
    )
  }

  /**
   * Select display type
   * @param displayType
   */
  selectDisplayType(displayType: DisplayType | null) {
    if (displayType === DisplayType.Diagram) {
      if (this.selectedFile) {
        // set zoom to false as this is not a zoom event
        this.mainState.setZoom(false);
        this.mainState.setImageLoading(true);
        this.mainState.setImageLoadingMessage('Loading image...');
        // metadata
        const metadataUrl = this.getFileMetadataUrl(this.selectedFile.rawData);
        console.log('rawFileInfo:', this.selectedFile.rawData);
        console.log('metadataUrl:', metadataUrl);
        this.getFileMetadata$(metadataUrl).subscribe({
            next: metadata => {
              const urls: any[] = [];
              if (metadata) {
                // retrieve and set imageCached
                if (metadata.zoomStatus) {
                  this.mainState.setImageCached(metadata.zoomStatus.cached);
                } else {
                  this.mainState.setImageCached(false);
                }
                // set filename
                this.mainState.setFilename(metadata.fileName);

                const imageMeta = metadata.imageMeta[0];
                const imgInfo = new ImageInfo();
                imgInfo.trueImageSize = [ imageMeta.x, imageMeta.y ];
                imgInfo.isGrayscale = imageMeta.channelCount === 1;
                imgInfo.showStack = false;
                imgInfo.scaleRatio = true;
                imgInfo.fileName = metadata.fileName;
                imgInfo.isStack = imageMeta.z !== 1;
                imgInfo.imageMeta = metadata.imageMeta;

                // set img info with isGrayscale and trueImageSize
                if (imageMeta.z === 1) {
                  const previewUrl = this.getPreviewUrl(this.selectedFile);
                  imgInfo.urls = [previewUrl];
                } else {
                  // image is stack
                  for (let i = 1; i <= imageMeta.z; i++) {
                    const url: string | undefined = this.getPreviewForSliceUrl(this.selectedFile, i);
                    urls.push(url);
                  }
                  imgInfo.urls = urls;
                }
                // Check if roi file exists
                this.roiFileExist(this.selectedFile).subscribe(roiFileExist => {
                  if (roiFileExist) {
                    this.selectedNode?.parent?.children?.forEach((node) => {
                      const geojsonName = this.selectedNode?.data?.name.substring(0, this.selectedNode?.data?.name.lastIndexOf('.')) + '.geojson';
                      // if the geojson node is found
                      if (geojsonName === node.data?.name) {
                        this.getContent(node.data.rawData).subscribe({
                          next: (content: string) => {
                            // set json content
                            imgInfo.roiJsonStr = content;
                            // set urls, trueImage size and isGrayScale
                            this.mainState.setImageInfo$(imgInfo);
                          },
                          error: (error) => {
                            console.error(`error loading roi file content: ${error.message}`);
                            this.mainState.setImageInfo$(imgInfo);
                          }
                        });
                      }
                    });
                  } else {
                    this.mainState.setImageInfo$(imgInfo);
                  }
                });
              }
            },
            error: err => {
              console.log('Error occured:' + err);
              this.messageService.add({ sticky: true, severity:'error', summary:'An error occured',
                                  detail:`The following error occured: ${err}.
                                  Please try to open the image again through the file navigator.` });
              this.mainState.setLoadingError(true);
              this.mainState.setImageLoading(false);
            },
            complete: () => {
              console.log('get file metadata complete');
            }
          });
      }
    }
  }

  /**
   * Check is geojson file for the given selectedFile exists in the same path
   * @param selectedFile
   * @return boolean
   */
  roiFileExist(selectedFile: IFileInfo | undefined): Observable<boolean> {
    const subject = new Subject<boolean>();
    const rawFile = new RawFileInfo();
    if (selectedFile) {
      rawFile.project = selectedFile.project;
      rawFile.relPath = selectedFile.relPath.substring(0, selectedFile.relPath.lastIndexOf('.')) + '.geojson';
      rawFile.type = selectedFile.type;
      rawFile.name = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')) + '.geojson';
      const geojsonUrl = this.getFileMetadataUrl(rawFile);
      this.getFileMetadata$(geojsonUrl).subscribe({
        next: metadata => {
          if (metadata)
            subject.next(true);
        }, error: err => {
          console.log('No geojson ROI file found:' + err);
          subject.next(false);
        }
      });
    } else {
      subject.next(false);
    }
    return subject.asObservable();
  }

  getSelectedFileInfo(): IFileInfo | undefined {
    return this.selectedFile;
  }

  getSelectedNode(): TreeNode<IFileInfo> | null {
    return this.selectedNode;
  }

  getRootNodes() {
    return this.rootNodes;
  }
  setRootNodes(rootNodes: TreeNode<IFileInfo>[]) {
    this.rootNodes = rootNodes;
  }

  isFileSelected$(): Observable<boolean> {
    return this.mainState.getSelectedFile$().pipe(
      map((info) => {
        return info != null;
      })
    );
  }

  public getProjects(): Observable<RawFileInfo[]> {
    return this.http.get<RawFileInfo[]>(this.url + 'projects');
  }

  public getChildren(parent: RawFileInfo): Observable<any[]> {
    return this.http.post<any[]>(`${this.url}children`, parent);
  }

  // in some cases building the IFileInfo object is a horrific experience and not
  // worth the effort...so allow an unchecked input parameter for these cases
  public getHackedPreviewUrl(fileInfo: any): string {
    const base64: string = this.encode(fileInfo);
    return this.url + 'preview?info=' + base64;
  }

  public getPreviewUrl(file: IFileInfo | undefined): string {
    if (file) {
      const base64: string = this.encode(file.rawData);
      return this.url + 'preview?info=' + base64;
    }
    return this.url;
  }

  public getPreviewSizeUrl(file: IFileInfo): string {
    const base64: string = this.encode(file.rawData);
    return this.url + 'previewsize?info=' + base64;
  }

  public getPreviewForSliceUrl(file: IFileInfo | undefined, index: number): string | undefined {
    if (file) {
      const base64: string = this.encode(file.rawData);
      return `${this.url}preview?info=${base64}&zIndex=${index}`;
    }
    return undefined;
  }


  public getFileMetadataUrl(file: RawFileInfo): string {
    const base64: string = this.encode(file);
    return `${this.url}metadata?info=${base64}`;
  }

  public getFileMetadata$(url: string): Observable<FileMetadata> {
    return this.getfileMetaData$.pipe(switchMap(() => {
      return this.http.get<FileMetadata>(url)
    }));
  }

  public getPreviewSize$(url: string): Observable<any> {
    return this.http.get<any>(url);
  }

  public getDownloadUrl(file: IFileInfo): string | null {
    if (file.rawData) {
      const base64: string = this.encode(file.rawData);
      return this.url + 'download?info=' + base64;
    } else {
      return null;
    }
  }

  // zoom requests
  public openZoomSession(file: IFileInfo) {
    const httpOptions: any = { headers: { 'Content-Type': 'application/json' }, responseType: 'text' };
    return this.http.post<any>(`${this.url}zoom/open-session`, file.rawData, httpOptions);
  }

  public zoomOnRegion(zoomRequest: ZoomRequest): Observable<any> {
    return this.zoomOnRegionAction$.pipe(switchMap(() => {
      const httpOptions: any = { headers: { 'Content-Type': 'application/json', 'Accept': 'image/png' }, responseType: 'arraybuffer' };
      return this.http.post<any>(`${this.url}zoom/region`, zoomRequest, httpOptions);
    }));
  }

  public download(file: IFileInfo) {
    const url = this.getDownloadUrl(file);
    if (url) {
      return this.http.get(url, { responseType: 'blob' });
    } else {
      console.error('Could not find file info for download');
      throw new Error(`Failed to build URL for file info ${file.toString()}`);
    }
  }

  public encode(file: any): string {
    const json: string = JSON.stringify(file);
    return btoa(json);
  }

  public deleteFile(file: RawFileInfo): Observable<IFileInfo> {
    return this.http.post<IFileInfo>(`${this.url}delete`, file);
  }

  // in some cases building the IFileInfo object is a horrific experience and not
  // worth the effort...so allow an unchecked input parameter for these cases
  public getHackedContentUrl(fileInfo: any): string {
    const base64: string = this.encode(fileInfo);
    return this.url + 'content?info=' + base64;
  }

  public getContentUrl(file: RawFileInfo): string {
    const base64: string = this.encode(file);
    return this.url + 'content?info=' + base64;
  }

  public getContent(file: RawFileInfo): Observable<string> {
    const url: string = this.getContentUrl(file);
    return this.http.get(url, { responseType: 'text' });
  }

  private getTableDataUrl(file: RawFileInfo): string {
    const base64: string = this.encode(file);
    return this.url + 'table?info=' + base64;
  }

  public getTableData(info: RawFileInfo): Observable<TableData> {
    const url: string = this.getTableDataUrl(info);
    return this.http.get<TableData>(url);
  }

  public getResultsSummary(info: RawFileInfo): Observable<ResultsSummary> {
    const base64: string = this.encode(info);
    const url: string = this.url + 'summary?info=' + base64;
    return this.http.get<ResultsSummary>(url);
  }

  public getHumanFileSize(info: ImageFile) {
    if (info == null) return '';
    if (info.type == 'Project') return '';
    if (info.size < 0) return '';
    return this.humanFileSize(info.size, true);
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  public humanFileSize(bytes: number, si = true, dp = 1): string {

    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
