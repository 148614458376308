export class Region {

  name?: string;
  bounds?: Rectangle | Polygon | null = null;
  color?: string; // [r, g, b]
  label?: string;
  bytesPerPixel = 8;
  /**
   * resolution is the resolution at which the region
   * was found. In order to get it in the full image
   * coordinates it will normally be 0. However if
   * there is a mistake with the resolution it may not
   * be. We return the resolution here in order to be
   * sure what it is. The server may need to be changed
   * to ensure that results regions are always returned
   * at resolution = 0
   */
  resolution = 0;
  cropped = false;
  tileNumber = 0;
  tileCoordinates: number[] | null = null;

  toString() {
    // if shape is rectangle
    if (this.bounds instanceof Rectangle) {
      return `x: ${this.bounds.x}, y: ${this.bounds.y},
              width: ${this.bounds.width}, height: ${this.bounds.height}`;
    } else if (this.bounds instanceof Polygon){
      let path = 'M';
      // display as path
      for (let i = 0; i < this.bounds.npoints; i++) {
        if (i < this.bounds.npoints - 1) {
          path = `${path}${this.bounds.xpoints[i]},${this.bounds.ypoints[i]}L`;
        } else {
          // if last one
          path = `${path}${this.bounds.xpoints[i]},${this.bounds.ypoints[i]}Z`;
        }
      }
      return path;
    }
    return '';
  }
}

export class Rectangle {
  x = 0;
  y = 0;
  width = 0;
  height = 0;
}

export class Polygon {
  npoints = 0;
  xpoints: number[] = [];
  ypoints: number[] = [];
  coordinates: number[][] = [];
}
