<div class="header">
  <p-toolbar styleClass="p-0 bg-white border-noround px-2 toolbar-nav">
    <div class="w-7 p-toolbar-group-left flex">
      <img src="./assets/jax-logo.png" alt="JAX Logo" class="mx-2 mt-1" />
      <h2 class="font-semibold text-indigo-800 uppercase">
        {{ title }}
      </h2>
    </div>

    <div class="align-items-right px-4">
      <ng-container *ngIf="user$ | async as user">
        <i class="pi pi-user"></i>

        <span class="font-medium pl-2">{{ user.name }} <{{ user.email }}></span>
      </ng-container>
    </div>

    <div class="p-toolbar-group-right">

      <div *ngIf="(auth.isAuthenticated$ | async) === false; then loginSection; else userMenu"></div>

      <ng-template #loginSection>
        <button
          pButton
          type="button"
          id="login-button"
          class="p-button-text"
          label="Sign In"
          icon="pi pi-sign-in"
          (click)="login()"
        ></button>
      </ng-template>

      <ng-template #userMenu>
				<button
				  pButton
				  type="button"
				  id="cli-button"
				  class="p-button-text p-button-plain"
				  label="Get CLI"
					icon="pi pi-arrow-circle-down"
					(click)="showDialog = true"
				></button>
        <button
          pButton
          type="button"
          id="logout-button"
          class="p-button-text p-button-plain"
          label="Sign Out"
          icon="pi pi-sign-out"
          (click)="logout()"
        ></button>
      </ng-template>
    </div>
  </p-toolbar>
</div>

<p-dialog [(visible)]="showDialog" [style]="{width: '800px'}" [modal]="true">
 
	<div class="w-9 p-toolbar-group-left flex">
    <img src="./assets/jax-logo.png" alt="JAX Logo" class="mx-5 mt-3" />
    <h3>JAX Image Tools Command Line Interface</h3>
  </div>
	
	A command line interface that allows you to do all the things in the 
	web application from the comfort of your command line. Here is how to use it:<br/>
	<ol>
	  <li>Install <a href="https://docs.aws.amazon.com/corretto/latest/corretto-21-ug/downloads-list.html" target="_blank">Amazon Corretto</a></li>
	  
		<!-- TODO The jar file should not be here. Instead we should release the CLI on maven
			central and link to it from here. Other parts of the CIMG source code should also 
			be release there when we commit to open source mode. -->
		<li>Download the <a href="assets/cimg-cli-0.0.2-SNAPSHOT.jar" target="_blank">CLI</a> jar</li>
		<li>In a terminal or cmd window run 'java -jar cimg-*.jar'</li>
	</ol>
	
	Commands to get you started:<br/>
	<pre>
	help
	login
	project
	select
	process
	process &
	status 
	monitor
	results
	download
	</pre>
	Note: Check the help for shorter aliases for each command. The
	CLI saves what you were doing between sessions and when it
	restarts, it will pick up where you left off.
</p-dialog>