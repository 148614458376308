import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

  title = 'JAX Image Tools';
  user$ = this.auth.user$;
  
  showDialog = false;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) { }


  get_cli() {
    console.log('get_cli');
  }

  login() {
    this.auth.loginWithRedirect();
  }

  logout() {
    this.auth.logout(
      {
        returnTo: document.location.origin
      }
    )
  }

}
